import DermatoHistoryTemplate from '@/modules/questionnaire/components/steps/common/medical-background/dermato-history/DermatoHistoryTemplate';

import { BOOLEAN } from '@/modules/questionnaire/api/constants';

export default {
  title: 'Steps/MedicalBackground/DermatoHistory/DermatoHistoryTemplate',
  component: DermatoHistoryTemplate
};

const createStory = props => () => ({
  components: { DermatoHistoryTemplate },
  storyProps: props,
  wrapperStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template:
    '<div :style="$options.wrapperStyles"><dermato-history-template v-bind="$options.storyProps" /></div>'
});

export const base = createStory({
  treatedForSkinCancer: BOOLEAN.YES,
  glandularCancer: BOOLEAN.YES,
  breastCancer: BOOLEAN.YES,
  breastCancerVisible: true,
  hasHerpes: BOOLEAN.YES,
  sufferedFromAcneRosacea: BOOLEAN.YES,
  sufferedFromAcneRosaceaVisible: true
});

export const negative = createStory({
  treatedForSkinCancer: BOOLEAN.NO,
  glandularCancer: BOOLEAN.NO,
  breastCancer: BOOLEAN.NO,
  breastCancerVisible: true,
  hasHerpes: BOOLEAN.NO,
  sufferedFromAcneRosacea: BOOLEAN.NO,
  sufferedFromAcneRosaceaVisible: true
});

export const requiredAndBreastCancerQuestions = createStory({
  treatedForSkinCancer: BOOLEAN.YES,
  glandularCancer: BOOLEAN.YES,
  breastCancer: BOOLEAN.NO,
  breastCancerVisible: true,
  hasHerpes: BOOLEAN.YES
});

export const requiredAndSufferedFromAcneRosaceaQuestions = createStory({
  treatedForSkinCancer: BOOLEAN.YES,
  glandularCancer: BOOLEAN.YES,
  hasHerpes: BOOLEAN.YES,
  sufferedFromAcneRosacea: BOOLEAN.NO,
  sufferedFromAcneRosaceaVisible: true
});
