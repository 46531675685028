<template>
  <div class="q-w360 q-fit">
    <question-with-error-wrap :error="getFieldsError(['treatedForSkinCancer'])">
      <single-answer-question
        title="label.treatedForSkinCancer"
        hint="info.treatedForSkinCancer"
        :value="treatedForSkinCancer"
        :options="$options.yesNoResponseOptions"
        yes-no
        @input="onFieldChange('treatedForSkinCancer', $event)"
      />
    </question-with-error-wrap>

    <question-with-error-wrap class="q-mt24 q-mt32-md" :error="getFieldsError(['glandularCancer'])">
      <single-answer-question
        id="glandular-cancer"
        title="label.glandularCancer"
        hint="info.glandularCancer"
        :value="glandularCancer"
        :options="$options.yesNoResponseOptions"
        yes-no
        @input="onFieldChange('glandularCancer', $event)"
      />
    </question-with-error-wrap>

    <fade-in-transition-wrap>
      <question-with-error-wrap
        v-if="breastCancerVisible"
        class="q-mt24 q-mt32-md"
        :error="getFieldsError(['breastCancer'])"
      >
        <single-answer-question
          id="breast-cancer"
          title="label.breastCancer"
          :value="breastCancer"
          :options="$options.yesNoResponseOptions"
          yes-no
          @input="onFieldChange('breastCancer', $event)"
        />
      </question-with-error-wrap>
    </fade-in-transition-wrap>

    <question-with-error-wrap class="q-mt24 q-mt32-md" :error="getFieldsError(['hasHerpes'])">
      <single-answer-question
        id="herpes"
        title="label.hasHerpes"
        hint="info.hasHerpes"
        :value="hasHerpes"
        :options="$options.yesNoResponseOptions"
        yes-no
        @input="onFieldChange('hasHerpes', $event)"
      />
    </question-with-error-wrap>

    <fade-in-transition-wrap>
      <question-with-error-wrap
        v-if="sufferedFromAcneRosaceaVisible"
        class="q-mt24 q-mt32-md"
        :error="getFieldsError(['sufferedFromAcneRosacea'])"
      >
        <single-answer-question
          id="acne-rosacea"
          title="label.sufferedFromAcneRosacea"
          hint="info.sufferedFromAcneRosacea"
          :value="sufferedFromAcneRosacea"
          :options="$options.yesNoResponseOptions"
          yes-no
          @input="onFieldChange('sufferedFromAcneRosacea', $event)"
        />
      </question-with-error-wrap>
    </fade-in-transition-wrap>
  </div>
</template>

<script>
import FadeInTransitionWrap from '@/components/wrappers/FadeInTransitionWrap';
import SingleAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/SingleAnswerQuestion';
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

import { YES_NO_RESPONSE_OPTIONS } from '@/modules/questionnaire/constants/questions';

export default {
  name: 'DermatoHistoryTemplate',
  components: { FadeInTransitionWrap, QuestionWithErrorWrap, SingleAnswerQuestion },
  mixins: [stepTemplateMixin],
  yesNoResponseOptions: YES_NO_RESPONSE_OPTIONS,
  props: {
    treatedForSkinCancer: {
      type: String,
      required: true
    },
    glandularCancer: {
      type: String,
      required: true
    },
    breastCancer: {
      type: String,
      default: ''
    },
    breastCancerVisible: {
      type: Boolean,
      default: false
    },
    hasHerpes: {
      type: String,
      required: true
    },
    sufferedFromAcneRosacea: {
      type: String,
      default: ''
    },
    sufferedFromAcneRosaceaVisible: {
      type: Boolean,
      default: false
    }
  }
};
</script>
